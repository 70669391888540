import { createSlice } from '@reduxjs/toolkit'
import history from '../../history'
import { requestStatus } from '../Constants'


const initialState = {
  Holidays: [],
  Hospital: {},
  Therapists: [],
  Nurses: [],
  Patients: [],
  Realtime: [],
  Leaves: [],
  User: {},
  Settings: {},
  Roles: [],
  Role: {},
  Loading : true,
}

export const Data = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setAllData: (state, action) => {
      return{
        ...state,
        Hospital: action.payload.Hospital,
        Holidays: action.payload.Holiday,
        Nurses: action.payload.Nurses,
        Therapists: action.payload.Therapists,
        Patients: action.payload.Patients,
        Realtime: action.payload.Realtime,
        Leaves: action.payload.Leaves,
        Settings: action.payload.Settings,
        Roles: action.payload.Roles,
        Role: action.payload.Role,
        Loading : false,
      }
    },
    setUserToken: (state, action) => {
      sessionStorage.setItem("data", JSON.stringify(action.payload));
      history.push("/");
      history.go("/");
    },
    setUser: (state, action) => {
      return{
        ...state,
      User: action.payload
      }
    },
    createTherapist: (state, action) => {
      return {
        ...state,
        Therapists: [
          ...state.Therapists,
          action.payload
        ]
      }
    },
    updateTherapist: (state, action) => {
      state.Therapists = state.Therapists.map(x => {
        if(x._id === action.payload._id){
          return {
            ...x,
            ...action.payload
          }
        }
        else return x
      })
    },
    deleteTherapist: (state, action) => {
      state.Therapists = state.Therapists.map(x => {
        if(x._id === action.payload._id){
          x.active = false
        }
        return x
      })
    },
    logout: (state, action) => {
        sessionStorage.removeItem("data");
        history.push("/login");
        history.go("/login");
    },

    addRealtime: (state, action) => {
      return {
        ...state,
        Realtime: [
          action.payload,
          ...state.Realtime,
          
        ]
      }
    },
    removeRealtime: (state, action) => {
      return {
        ...state,
        Realtime: state.Realtime.filter(x => x._id !== action.payload)
      }
    },
    readRealtime: (state, action) => {
      return {
        ...state,
        Realtime: state.Realtime.map(x => {
          if(x?._id?.toString() === action?.payload?.toString()){
            return {
              ...x,
              read: true
            }
          }else{
            return x
          }
        })
      }
    },
    rejectRequest: (state, action) => {
      state.Realtime = state.Realtime.map(x => {
        if(x._id === action.payload){
          x.status = requestStatus.rejected
        }
        return x
      })
    },
    acceptRequest: (state, action) => {
      state.Realtime = state.Realtime.map(x => {
        if(x._id === action.payload){
          x.status = requestStatus.approved
        }
        return x
      })
    },


    createNurse: (state, action) => {
      return {
        ...state,
        Nurses: [
          ...state.Nurses,
          action.payload
        ]
      }
    },
    updateNurse: (state, action) => {
      state.Nurses = state.Nurses.map(x => {
        if(x._id === action.payload._id){
          return {
            ...x,
            ...action.payload
          }
        }
        else return x
      })
    },
    deleteNurse: (state, action) => {
      state.Nurses = state.Nurses.map(x => {
        if(x._id === action.payload._id){
          x.active = false
        }
        return x
      })
    },

    leaveResponse: (state, action) => {
      return {
        ...state,
        Leaves: action.payload
      }
    },

    createPatient: (state, action) => {
      return {
        ...state,
        Patients: [
          ...state.Patients,
          action.payload
        ]
      }
    },
    updatePatient: (state, action) => {
      state.Patients = state.Patients.map(x => {
        if(x._id === action.payload._id){
          return {
            ...x,
            ...action.payload
          }
        }
        else return x
      })
    },
    deletePatient: (state, action) => {
      state.Patients = state.Patients.map(x => {
        if (x._id?.toString() === action.payload?.toString()) {
          return {
            ...x,
            active: !x.active
          }          
        }else{
          return x
        }
      })
    },

    holidayResponse: (state, action) => {
      return {
        ...state,
        Holidays: action.payload
      }
    },

    updateSettings: (state, action) => {
      return {
        ...state,
        Settings: action.payload
      }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { setAllData, setUserToken, setUser, logout } = Data.actions

export const { addRealtime, removeRealtime, rejectRequest, acceptRequest, readRealtime} = Data.actions

export const { createTherapist, updateTherapist, deleteTherapist, createNurse, updateNurse, deleteNurse, leaveResponse, createPatient, updatePatient, deletePatient, holidayResponse} = Data.actions

export const { updateSettings } = Data.actions

export default Data.reducer